import React from "react";
import { Box, Avatar, Typography, Button, Modal, Paper, TextField, } from "@material-ui/core";
import CommentsController, { Props } from "./CommentsController";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


const styles = {
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paper: {
        width: 588,
        height: 251,
        outline: 'none',
        borderRadius: '8px',
    },
    heading: {
        maxWidth: '100%',
        height: 53,
        backgroundColor: '#5497FF',
        borderRadius: '8px 8px 0px 0px',
        fontSize: "16px",
        display: 'flex',
        alignItems: 'center',
        fontWeight: 400,
        color: 'white',
        bottom: '10px',
        paddingLeft: '23px'
    },
    avatar: {
        width: '48px',
        height: '48px',
        alignItems:'start',
        marginBottom:'65px'
    },
    dialogContent: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems:'center',
        margin:'20px',
        gap: 16,
    },
    inputField: {
        hieght:'fit',
        width:'100%',
    },
    actionButton: {
        minWidth: 120,
        height: '44px',
        width: '270',
        marginRight: '1rem',
    },
    cancelButton: {
        minWidth: 120,
        height: '44px',
        width: '270',
        marginRight: '1rem',
        backgroundColor: 'white',
        color: '#5497FF',
        borderRadius: 8
    },
    buttonGroup: {
        display: 'flex',
        borderRadius: '8px',
        bottom: '8px',
        left:'8px',
        height: '60px',
        width: '588px'
    },
};

class Comments extends CommentsController {
    constructor(props: Props) {
        super(props);
    }

    renderChatUi = () => {
        if (!this.state.isChatOpen) {
            return <Box style={{ marginTop: "16px", paddingTop: '0px', padding: '24px', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" style={{ marginBottom: "16px" }}>
                    Private Message
                </Typography>
                {this.state.commentUsers.map((user) => (
                    <>
                        <Box
                            key={user.id}
                            style={{
                                marginBottom: "16px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                
                            }}
                            onClick={this.handleChatOpen.bind(this, user?.account?.data?.id)}
                        >
                            <Box style={{ display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                                <Avatar
                                    src={user?.account?.data?.attributes?.photo}
                                    alt={user.name || 'John Doe'}
                                    style={{ marginRight: "16px", height: '56px', width: '56px', marginTop: '2px' }}
                                />
                                <Box>
                                    <Box>
                                        <Typography>{user?.account?.data?.attributes?.email}</Typography>
                                    </Box>


                                    <Box>
                                        <Typography variant="body1" color="textSecondary">
                                            {user.last_comment.comment}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Typography variant="body2" color="textSecondary">
                                {user.last_comment?.created_at}
                            </Typography>
                        </Box>
                        <hr style={{ border: '1px solid #CBD5E1', width: '100%' }}></hr>
                    </>
                ))}
            </Box>
        } else {
            return (
                <Box style={{ marginTop: "16px", padding: '24px', display: 'flex', flexDirection: 'column' }}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h6" style={{ marginBottom: "16px", display: "flex" }}>
                            <ArrowBackIcon style={{ display: 'flex', marginRight: '10px', color: "#000000", alignItems: 'center' }} onClick={this.handleBack} />
                            Private Message
                            <Button onClick={this.handleAddChat} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end', marginTop: '-49px', marginLeft: 'auto', cursor: 'pointer' }}>Add</Button>
                        </Typography>
                    </Box>
                    {this.state.commentHistoryData.map((user) => (
                        <>
                            <Box
                                key={user.id}
                                style={{
                                    marginBottom: "16px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box style={{ display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                                    <Avatar
                                        src={user.avatar}
                                        alt={user.name || 'John Doe'}
                                        style={{ marginRight: "16px", height: '56px', width: '56px', marginTop: '2px' }}
                                    />
                                    <Box>
                                        <Box>
                                            <Typography>{user?.attributes?.author_name}</Typography>
                                        </Box>

                                        <Box>
                                            <Typography variant="body1" color="textSecondary">
                                                {user?.attributes?.comment}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Typography variant="body2" color="textSecondary">
                                    {user.attributes?.created_at}
                                </Typography>
                            </Box>
                            <hr style={{ border: '1px solid #CBD5E1', width: '100%' }}></hr>
                        </>
                    ))}
                    <Modal open={this.state.isOpenAddChat} style={styles.modal}>

                        <Paper style={styles.paper}>
                            <Box>
                                <Typography style={styles.heading}>Private Message</Typography>
                            </Box>

                            <Box style={styles.dialogContent} textAlign={"center"}>
                                <Avatar
                                    alt="User Avatar"
                                    src="https://i.pravatar.cc/150?img=3"
                                    style={styles.avatar}
                                />
                                <TextField
                                    style={styles.inputField}
                                    placeholder="Hey, share what's on your mind..."
                                    multiline
                                    rows={4}
                                    value={this.state.message}
                                    onChange={this.handleChange}
                                />
                            </Box>
                            <Box
                                style={styles.buttonGroup}
                                justifyContent={"center"}
                                position={"relative"}
                            >
                                <Button
                                    onClick={this.handleClose}
                                    variant="outlined"
                                    color="primary"
                                    style={styles.cancelButton}
                                >
                                    CANCEL
                                </Button>
                                <Button
                                    onClick={this.handleSend.bind(this)}
                                    variant="contained"
                                    color="primary"
                                    style={styles.actionButton}
                                >
                                    SEND
                                </Button>
                            </Box>
                        </Paper>
                    </Modal>
                </Box >
            )
        }
    }

    render() {
        return (
            <Box
                style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "8px",
                    padding: "16px",
                    fontFamily: "Nunito",
                    fontSize: "12px",
                    fontWeight: 600,
                    color: "#475569",
                }}
            >
                {
                    this.renderChatUi()
                }
            </Box>
        );
    }
}


export default Comments
