import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getAuthToken } from "../../../components/src/NativeWebRouteWrapper/Utils";
import i18next, {t} from "i18next";
export interface OtherInterestFormField {
  name: string,
  interest_category: string,
  created_by_admin: boolean
}

export interface OtherInterestSkillsFormField {
  name: string,
  interest_category: string,
  created_by_admin: boolean
}
const otherInterestSkillsAddMoreField : OtherInterestSkillsFormField = {
  name: "", interest_category: "", created_by_admin: false

}
const otherInterestAddMoreField: OtherInterestFormField = {
  name: "", interest_category: "", created_by_admin: false
};

interface IObjectKeys {
  [key: string]: [];
}

interface ProfileInitialValues {
  full_name: string;
  email: string;
  full_phone_number: string;
  gender: string,
  country: string,
  state: string,
  city: string,
  profession: string,
  preferred_language: number | string;
  selected_interest: any[];
  partnership_practitioner: string,
  accredited_partnership_broker: string,
  organizational_affilation: string,
  interest_name: any[]
  skill_name: any[]
  about_yourself: string
  experience: string
  photo: any
  working_language: string
  time_zone: string
}

interface ProfileErrorValues {
  nameError: string;
  emailError: string;
  phoneError: string;
  genderError: string,
  countryError: string,
  stateError: string,
  cityError: string,
  professionError: string,
  languageError: number | string;
  partnershipError: string,
  brokerError: string,
  affiliationsError: string
  experienceError: string
}

interface PasswordInitialValues {
  current_password: string;
  new_password: string;
  confirm_password: string;
}

interface PasswordErrorValues {
  currentPasswordError: string;
  newPasswordError: string;
  confirmPasswordError: string;
}

interface ValidateFields {
  key: string, label: string, regexp: RegExp | null, errorKey: string
}

type Color = "success" | "info" | "warning" | "error" | undefined;

const ProfileFormField: ProfileInitialValues = { time_zone: '', working_language: '', photo: '', full_name: "", email: "", full_phone_number: "", gender: "", country: "", state: "", city: "", profession: "", preferred_language: '', selected_interest: [], partnership_practitioner: "", accredited_partnership_broker: "", organizational_affilation: "", interest_name: [], skill_name: [], about_yourself: '', experience: '' };
const ProfileErrorField: ProfileErrorValues = { nameError: "", emailError: "", phoneError: "", genderError: "", countryError: "", stateError: "", cityError: "", professionError: "", languageError: '', partnershipError: "", brokerError: "", affiliationsError: "", experienceError: "" };

const PasswordFormField: PasswordInitialValues = { current_password: "", new_password: "", confirm_password: "" };
const PasswordErrorField: PasswordErrorValues = { currentPasswordError: "", newPasswordError: "", confirmPasswordError: "" };

const Language: any = { english: "en", french: "fr", bengali: "bn" }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  activePage: string;
  showCurrentPassword: boolean;
  showChangePassword: boolean;
  showConfirmPassword: boolean;
  interestList: IObjectKeys;
  showOtherSkillsFields: boolean,
  showOtherImpactFields: boolean,
  otherInterestImpactFields: OtherInterestFormField[];
  otherInterestSkillsFields: OtherInterestSkillsFormField[];
  ProfileFormField: ProfileInitialValues;
  ProfileErrorField: ProfileErrorValues;
  PasswordFormField: PasswordInitialValues;
  PasswordErrorField: PasswordErrorValues;
  countryList: any[];
  stateList: any[];
  cityList: any[];
  userInfo: any;
  interestIds: number[],
  selected_interest:any,
  showSnackbar: boolean;
  severity: Color;
  message: string;
  imagePreview: any
  staticInterestList: any[]
  staticInterestSelectedList: any[]
  staticSkillList: any[]
  staticSkillSelectedList: any[]
  imageError: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings5Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetInterest: any;
  apiGetCountry: any;
  apiGetState: any;
  apiGetCity: any;
  apiEditProfile: any;
  apiEditPassword: any;
  token = getAuthToken();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.NavigationPayLoadMessage),
        getName(MessageEnum.CountryCodeMessage)
      
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      activePage: 'profile',
      showCurrentPassword: false,
      showChangePassword: false,
      showConfirmPassword: false,
      interestList: {},
      showOtherSkillsFields: false,
      showOtherImpactFields: false,
      otherInterestImpactFields: [{ ...otherInterestAddMoreField }],
      otherInterestSkillsFields: [{ ...otherInterestSkillsAddMoreField }],
      ProfileFormField: ProfileFormField,
      ProfileErrorField: ProfileErrorField,
      PasswordFormField: PasswordFormField,
      PasswordErrorField: PasswordErrorField,
      countryList: [],
      stateList: [],
      cityList: [],
      userInfo: {},
      interestIds: [],
      selected_interest: [],
      showSnackbar: false,
      severity: undefined,
      message: "",
      imagePreview: null,
      staticInterestList: [
        {
          id: 1,
          name: "Sports"
        },
        {
          id: 2,
          name: "Music"
        },
        {
          id: 3,
          name: "Travel"
        },
        {
          id: 4,
          name: "Food"
        },
        {
          id: 5,
          name: "Technology"
        }
      ],
      staticInterestSelectedList: [],
      staticSkillList: [
        {
          id: 1,
          name: "Sports"
        },
        {
          id: 2,
          name: "Music"
        },
        {
          id: 3,
          name: "Travel"
        },
        {
          id: 4,
          name: "Food"
        },
        {
          id: 5,
          name: "Technology"
        }
      ],
      staticSkillSelectedList: [],
      imageError: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const apiSettingRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );

        switch (apiSettingRequestCallId) {
          case this.apiGetInterest:
            this.setState({ interestList: responseJson?.data });
            break;
          case this.apiGetCountry:
            this.setState({ countryList: responseJson?.data }, () => {
              if (this.state.userInfo?.country) {
                const countryObj = responseJson?.data?.find((item: any) => item?.attributes?.name === this.state.userInfo?.country)
                this.getStateList(countryObj?.id)
              }
            });
            break;
          case this.apiGetState:
            this.setState({ stateList: responseJson?.data }, () => {
              if (this.state.userInfo?.state) {
                const countryObj = this.state.countryList?.find((item: any) => item?.attributes?.name === this.state.userInfo?.country)
                const stateObj = responseJson?.data?.find((item: any) => item?.name === this.state.userInfo?.state)
                this.getCityList(countryObj?.id, stateObj?.id)
              }
            });
            break;
          case this.apiGetCity:
            this.setState({ cityList: responseJson?.data });
            break;
          case this.apiEditProfile:
            this.findUserEditApiCaller(responseJson); 
            break;
          case this.apiEditPassword:
            if (responseJson?.message) {
              this.setState({
                PasswordFormField: PasswordFormField,
                showSnackbar: true,
                message: responseJson.message,
                severity: "success",
              });
              this.props.navigation.navigate("UserProfileBasicBlock")
            
              break;
            }
            this.setState({
              showSnackbar: true,
              message: responseJson?.errors?.message,
              severity: "error",
            });
            break;
        }
      }
    // Customizable Area End
  }

  async componentDidMount() {
    const userInfo = JSON.parse(localStorage.getItem("userInfo")!)
    const interest_arr: any[] = userInfo.user_interests?.map((item: any) => ({ interest_id: item?.interest_id.toString() }))  || []
    const interest_id_arr: any[] = userInfo.user_interests?.map((item: any) => item?.interest_id.toString()) || []
    this.setState({ userInfo, 
      selected_interest: interest_arr, 
      interestIds: interest_id_arr,
      ProfileFormField: { ...userInfo, accredited_partnership_broker: userInfo?.accredited_partnership_broker.toString() } 
    })
    i18next.changeLanguage(Language[userInfo.preferred_language.toLowerCase()]);
    this.getInterestList();
    this.getCountryList()
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {this.setState({ txtInputValue: text})},
    secureTextEntry: false
  };

  txtInputMobileProps = {...this.txtInputWebProps, autoCompleteType: "email", keyboardType: "email-address"};

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible;
    },
  };

  txtInputProps = this.isPlatformWeb() ? this.txtInputWebProps : this.txtInputMobileProps;

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  btnExampleProps = {onPress: () => this.doButtonPressed()};

  setEnableField = () => {this.setState({enableField: !this.state.enableField})};

  // web events
  setInputValue = (text: string) => {this.setState({ txtInputValue: text })};

  // Customizable Area Start
  handleAddNewInterest = (data: any) => {
    this.setState({
      staticInterestList: [...this.state.staticInterestList, data]
    })
    this.handleAddExistingInterest(data)
  }

  handleAddExistingInterest = (data: any) => {
    this.setState({
      staticInterestSelectedList: [...this.state.staticInterestSelectedList, data]
    })
  }

  handleDeleteExistingInterest = (data: any) => {
    const filtered = this.state.staticInterestSelectedList.filter((item) => item.id != data.id)
    this.setState({ staticInterestSelectedList: filtered })
  }

  handleAddToUserInterest = () => {
    const filteredArray = this.state.staticInterestList.filter(item1 => {
      return !this.state.staticInterestSelectedList.some(item2 => item1.id === item2.id);
    });
    this.setState({
      ProfileFormField: { ...this.state.ProfileFormField, interest_name: [...this.state.ProfileFormField.interest_name, ...this.state.staticInterestSelectedList] },
      staticInterestList: filteredArray,
      staticInterestSelectedList: [],
    })

  }

  handleAddNewSkill = (data: any) => {
    this.setState({
      staticSkillList: [...this.state.staticSkillList, data]
    })
    this.handleAddExistingSkill(data)
  }

  handleAddExistingSkill = (data: any) => {
    this.setState({
      staticSkillSelectedList: [...this.state.staticSkillSelectedList, data]
    })
  }

  handleDeleteExistingSkill = (data: any) => {
    const filtered = this.state.staticSkillSelectedList.filter((item) => item.id != data.id)
    this.setState({ staticSkillSelectedList: filtered })
  }

  handleAddToUserSkill = () => {
    const filteredArray = this.state.staticSkillList.filter(item1 => {
      return !this.state.staticSkillSelectedList.some(item2 => item1.id === item2.id);
    });
    this.setState({
      ProfileFormField: { ...this.state.ProfileFormField, skill_name: [...this.state.ProfileFormField.skill_name, ...this.state.staticSkillSelectedList] },
      staticSkillList: filteredArray,
      staticSkillSelectedList: [],
    })
  }

  handleOASText = (value: string) => {
    this.setState({ ProfileFormField: { ...this.state.ProfileFormField, organizational_affilation: value } });
  };

  handleSEText = (value: string) => {
    this.setState({ ProfileFormField: { ...this.state.ProfileFormField, experience: value } });
  };

  deleteSkillsData = (chipToDelete: any) => {
    const newData =
      this.state.ProfileFormField.skill_name.filter((chip: any) => chip.id !== chipToDelete.id)
    this.setState({ ProfileFormField: { ...this.state.ProfileFormField, skill_name: newData } })
  }

  deleteInterestsData = (chipToDelete: any) => {
    const newData =
      this.state.ProfileFormField.interest_name.filter((chip: any) => chip.id !== chipToDelete.id)
    this.setState({ ProfileFormField: { ...this.state.ProfileFormField, interest_name: newData } })
  }

  handleImageSelect = (event: any) => {
    const file = event.target?.files![0]
    if (file.size < 10 * 1024 * 1024 && (file.type === "image/jpg" || file.type === "image/png")) {
      this.setState({
        ProfileFormField: { ...this.state.ProfileFormField, photo: '' }
      })
      this.setState({
        imagePreview: URL.createObjectURL(file),
        imageError: false
      })
      return
    }
    this.setState({
      imageError: true
    })
  };

  findUserEditApiCaller = (responseJson: any) => {
    if (responseJson?.data) {
              const res = {
                id: responseJson.data.id,
                ...responseJson.data.attributes,
              }
                this.setState({
                  showSnackbar: true,
                  message: t('Profile edit successfully'),
                  severity: "success",
                  userInfo: res 
                });
                localStorage.setItem(
                  "userInfo",
                  JSON.stringify(res)
                );
                this.props.navigation.navigate("UserProfileBasicBlock")
            } else if(responseJson?.errors){
              this.setState({
                showSnackbar: true,
                message: responseJson?.errors?.message,
                severity: "error",
              });
            }
  }
  handleOtherFields = (e: any, category: string, index: number) => {
      let state = {...this.state}
      let otherFields = (category === 'impact_area') ? [...state.otherInterestImpactFields] : [...state.otherInterestSkillsFields];
      otherFields[index] = { ...otherFields[index], name: e.target.value, interest_category: category };
      if(category === 'impact_area'){
        this.setState({  otherInterestImpactFields: otherFields});
      }else{
        this.setState({  otherInterestSkillsFields: otherFields});
      }
  }

  handleAddFormFields = (type: string) => {
    let state = {...this.state}
    let otherInterestFieldData = (type === 'impact_area') ? [...state.otherInterestImpactFields] : [...state.otherInterestSkillsFields];
    otherInterestFieldData.push({ ...otherInterestAddMoreField });
    if(type === 'impact_area'){
      this.setState({  otherInterestImpactFields: otherInterestFieldData});
    }else{
      this.setState({  otherInterestSkillsFields: otherInterestFieldData});
    }
  }
  
  handleRemoveAddMoreFields =(index: number,category: string) => {
    let state = {...this.state}
    let otherInterestFieldData = (category === 'impact_area') ? [...state.otherInterestImpactFields] : [...state.otherInterestSkillsFields];
    otherInterestFieldData.splice(index,1);
    if(category === 'impact_area'){
      this.setState({  otherInterestImpactFields: otherInterestFieldData});
    }else{
      this.setState({  otherInterestSkillsFields: otherInterestFieldData});
    }
  }
  handleShowCurrentPassword = () => {
    this.setState({ showCurrentPassword: !this.state.showCurrentPassword });
  };
  handleShowChangePassword = () => {
    this.setState({ showChangePassword: !this.state.showChangePassword });
  };
  handleShowConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };
  handleActiveTab= (type:string) => {
    this.setState({ activePage: type });
  };

  handleChange = (e: any) => {
    let value = e.target.value;

    if (e.target.name === "country") {
      const countryObj = this.state.countryList?.find((item: any) => item?.attributes?.name === value)
      this.getStateList(countryObj?.id)
      this.setState({ ProfileFormField: { ...this.state.ProfileFormField, [e.target.name]: value } });
    } else if (e.target.name === "state") {
      const countryObj = this.state.countryList?.find((item: any) => item?.attributes?.name === this.state.ProfileFormField.country)
      const stateObj = this.state.stateList?.find((item: any) => item?.name === value)
      this.getCityList(countryObj?.id, stateObj?.id)
      this.setState({ ProfileFormField: { ...this.state.ProfileFormField, [e.target.name]: value } });
    } else if (e.target.name === "city" || e.target.name === "preferred_language") {
      this.setState({ ProfileFormField: { ...this.state.ProfileFormField, [e.target.name]: value } });
    } else if (e.target.name === "number_visible") {
      this.setState({ ProfileFormField: { ...this.state.ProfileFormField, [e.target.name]: e.target.checked } });
    } else {
      this.setState({ ProfileFormField: { ...this.state.ProfileFormField, [e.target.name]: value.trimStart() } });
    }
  };

  handleDropdownData = (e: React.SyntheticEvent, value: any, fieldName: keyof ProfileInitialValues) => {
    if (fieldName === 'country') {
      this.setState((prevState) => {
        const shouldClearStates = prevState.ProfileFormField.country !== value?.title;
        return {
          ProfileFormField: {
            ...prevState.ProfileFormField,
            country: value?.title === undefined ? '' : value.title,
            state: shouldClearStates ? '' : prevState.ProfileFormField.state,
            city: shouldClearStates ? '' : prevState.ProfileFormField.city,
          },
        };

      });
    }
    else if (fieldName === 'state') {
      this.setState((prevState) => {
        const shouldClearStates = prevState.ProfileFormField.state !== value?.title;
        return {
          ProfileFormField: {
            ...prevState.ProfileFormField,
            state: value?.title === undefined ? '' : value.title,
            city: shouldClearStates ? '' : prevState.ProfileFormField.city,
          },
        };
      });
    }
    else {
      this.setState({ ProfileFormField: { ...this.state.ProfileFormField, [fieldName]: value?.title === undefined ? '' : value.title } })
    }
  }

  handlePasswordChange = (e: any) => {
    let value = e.target.value;
    this.setState({ PasswordFormField: { ...this.state.PasswordFormField, [e.target.name]: value.trimStart() } });
  };

  handleCloseSnackbar = () => {
    this.setState({
      showSnackbar: false,
      severity: undefined,
      message: "",
    });

    return true;
  };

  checkUniqueInterest = (otherInterestFields:any, interestsCategories:any) => {
    let valueArr = otherInterestFields.map(function (item:any) { return item.name });

    const isDuplicate = valueArr.some((item:any, idx:any) => valueArr.indexOf(item) != idx);
    if(isDuplicate) return true;

    let unique = false;
    otherInterestFields.forEach((other1: any) => {
      const check = interestsCategories.filter((other2: any) => (other1.name === other2.attributes.name));
      if(check.length) {
        unique = true;
      }
    });
    return unique;
  }

  handleUserInterestsAttributes = (selected_interest: any, otherInterestImpactFields: any, showOtherImpactFields: any,
    otherInterestSkillsFields: any, showOtherSkillsFields: any) => {
    let user_interests_attributes = [...selected_interest];
    if (otherInterestImpactFields.length && showOtherImpactFields === true) {
      const otherImpact = otherInterestImpactFields.map((item: any) => {
        return { interest_attributes: { ...item } }
      })
      user_interests_attributes = [...user_interests_attributes, ...otherImpact];
    }
    if (otherInterestSkillsFields.length && showOtherSkillsFields === true) {
      const otherSkills = otherInterestSkillsFields.map((item: any) => {
        return { interest_attributes: { ...item } }
      })
      user_interests_attributes = [...user_interests_attributes, ...otherSkills];
    }
    return user_interests_attributes
  }

  handleInterestValidation = (interest_attr: any) => {
    let flag = false;
    for (let item of interest_attr) {
      if (typeof item !== "string" && item.interest_attributes && item.interest_attributes.name === "") {
        flag = true
        this.setState({
          showSnackbar: true,
          message: t('Please fill other interest field.'),
          severity: "error",
        });
        break;
      }
    }
    return flag
  }

  //istanbul ignore next/
  handleProfileSubmit = (e: any) => {
    e.preventDefault();
    if (this.handleValidation(this.state.ProfileFormField)) {
      const {selected_interest, otherInterestImpactFields, showOtherImpactFields, otherInterestSkillsFields, showOtherSkillsFields, interestList, userInfo} = this.state
      const new_selected_interest = selected_interest.filter((item: any) =>!userInfo?.user_interests?.some((interest: any) => interest.interest_id == item.interest_id))
      const removed_interest = userInfo?.user_interests.filter((item: any) =>!selected_interest.some((interest: any) => interest.interest_id == item.interest_id))
      const user_interests_attributes = this.handleUserInterestsAttributes(new_selected_interest, otherInterestImpactFields,
        showOtherImpactFields, otherInterestSkillsFields, showOtherSkillsFields)
      if(selected_interest.length === 0 && user_interests_attributes.length === 0 && new_selected_interest.length === 0){
        return  this.setState({
          showSnackbar: true,
          message: t('Please select any interest.'),
          severity: "error",
        });
      }
      const isOtherInterestFieldEmpty = this.handleInterestValidation(user_interests_attributes)  
      if(isOtherInterestFieldEmpty) return; 
      let checkRes = this.checkUniqueInterest(otherInterestImpactFields, interestList.impact_area);
      let checkRes2 = this.checkUniqueInterest(otherInterestSkillsFields, interestList.skills_and_approaches);
        if(checkRes || checkRes2){
        return this.setState({
            showSnackbar: true,
            message: t('Interest name should not be duplicate.'),
            severity: "error",
          });
        }
     let finalData = { ...this.state.ProfileFormField, user_interests_attributes, removed_interest } as any;
     delete finalData.selected_interest;
    //  this.editProfile(finalData)
    } else {
            return this.setState({
        showSnackbar: true,
        message: t('Invalid profile details'),
        severity: "error",
      });
    }
  }

  handlePasswordSubmit = (e: any) => {
    e.preventDefault();
    if (this.handlePasswordValidation(this.state.PasswordFormField)) {
     this.editPasswrod(this.state.PasswordFormField)
    } else {
      return this.setState({
        showSnackbar: true,
        message: t('Invalid password details'),
        severity: "error",
      });
    }
  }

  validateFormField = (field: ValidateFields, data: ProfileInitialValues | PasswordInitialValues) => {

    const name = field.key as keyof typeof data;
    if (data[name] === "") {
      return t(`${field.label}`)+ t(`is required`);
    }
    if (field.regexp && (!field.regexp.test(data[name] as string))) {
      return t(`${field.label}`) + t(`is invalid`);
    }
    return true;
  }

  validateEditorText = (key: keyof ProfileInitialValues, data: ProfileInitialValues) => {
    if (data[key] === null || data[key] === undefined || data[key].replace(/<[^>]*>/g, '').trim().length === 0) {
      return t('It is required field')
    }
  }
  validateEditFormField = (field: ValidateFields, data: ProfileInitialValues) => {
    const name = field.key as keyof typeof data;
    if (name === 'experience' || name=== 'organizational_affilation') {
      return this.validateEditorText(name,data)
    }
    if (data[name] === "") {
      return t(`It is required field`);
    }
    if (field.regexp && (!field.regexp.test(data[name] as string))) {
      return t(`It is required field`);
    }
    return true;
  }

  handleValidation = (formState: ProfileInitialValues) => {
    const validateFields = [
      { key: 'full_name', label: "Name", regexp: /^[a-zA-Z ]{2,40}$/, errorKey: 'nameError' },
      { key: 'email', label: "Email", regexp: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, errorKey: 'emailError' },
      { key: 'full_phone_number', label: "Phone Number", regexp: /^\d{10}$/, errorKey: 'phoneError' },
      { key: 'gender', label: "Gender", regexp: /^[a-zA-Z ]{2,40}$/, errorKey: 'genderError' },
      { key: 'country', label: "Country", regexp: null, errorKey: 'countryError' },
      { key: 'state', label: "State", regexp: null, errorKey: 'stateError' },
      { key: 'city', label: "City", regexp: null, errorKey: 'cityError' },
      { key: 'preferred_language', label: "Preferred Language", regexp: /^[a-zA-Z ]{2,40}$/, errorKey: 'languageError' },
      { key: 'selected_interest', label: "Interested in", regexp: null, errorKey: 'selected_interest' },
      { key: 'time_zone', label: "Time zone", regexp: /^[a-zA-Z ]{2,40}$/, errorKey: 'zoneError' },
      { key: 'working_language', label: "Working Language", regexp: /^[a-zA-Z ]{2,40}$/, errorKey: 'workingLanguageError' },
      { key: 'organizational_affilation', label: "organizational_affilation", regexp: null, errorKey: 'affiliationsError' },
      { key: 'experience', label: "experience", regexp: null, errorKey: 'experienceError' }];
    let isValid = true;
    let errorFields = { ...this.state.ProfileErrorField };
    validateFields.forEach((itm: ValidateFields) => {
      const res = this.validateFormField(itm, formState);
      errorFields = { ...errorFields, [itm.errorKey]: (res === true) ? "" : res };
      if (res !== true) {
        isValid = false;
      }
    });
    this.setState({ ProfileErrorField: errorFields });
    return isValid;
  };

  handlePasswordValidation = (formState: PasswordInitialValues) => {

    const validateFields = [
      { key: 'current_password', label: "Current Password", regexp: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d[\]{};:=<>_+^#$@!%*?&]{8,}$/, errorKey: 'currentPasswordError' },
      { key: 'new_password', label: "New Password", regexp: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d[\]{};:=<>_+^#$@!%*?&]{8,}$/, errorKey: 'newPasswordError' },
      { key: 'confirm_password', label: "Confirm Password", regexp: null, errorKey: 'confirmPasswordError' }];
    let isValid = true;
    let errorFields = { ...this.state.PasswordErrorField };
    validateFields.forEach((itm: ValidateFields) => {
      const res = this.validateFormField(itm, formState);
      errorFields = { ...errorFields, [itm.errorKey]: (res === true) ? "" : res };
      if (res !== true) {
        isValid = false;
      }
    });
    if (formState.new_password !== formState.confirm_password) {
      errorFields = { ...errorFields, confirmPasswordError: t('Confirm Password does not match') };
      isValid = false;
    }
    this.setState({ PasswordErrorField: errorFields });
    return isValid;
  };

  handleSelectInterest = (id: number) => {
    if (this.state.interestIds.includes(id)) {
      let idsArray = [...this.state.interestIds];
      let selectedIdsArray = [...this.state.selected_interest];
      idsArray = idsArray.filter(item => item !== id);
      const indxKey = selectedIdsArray.findIndex(item => item.interest_id === id)
      selectedIdsArray.splice(indxKey, 1)
      this.setState({ selected_interest: selectedIdsArray , interestIds: idsArray });
    } else {
      const interest_arrs = this.state.selected_interest;
      interest_arrs.push({ 'interest_id': id });
      const interest_id_arr = this.state.interestIds;
      interest_id_arr.push(id);
      this.setState({ selected_interest: interest_arrs , interestIds: interest_id_arr });
    }
  }

  handleShowFields = (type: string) => {
    if (type === "impact_area") {
      
        this.setState({ showOtherImpactFields: !this.state.showOtherImpactFields, otherInterestImpactFields: this.state.showOtherImpactFields? []: [{ ...otherInterestAddMoreField }]})
     
    } else {
        this.setState({ showOtherSkillsFields: !this.state.showOtherSkillsFields, otherInterestSkillsFields:this.state.showOtherSkillsFields ? []: [{ ...otherInterestAddMoreField }]})
    }
  }

  getInterestList = () => {
    const headerData = { "Content-Type": configJSON.validationApiContentType };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetInterest = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getInterestAPiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCountryList = () => {

    const headerObj = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetCountry = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCountryAPiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerObj)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getStateList = (country: string) => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetState = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCountryAPiEndPoint}/state_list?alpha2=${country}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCityList = (country:string, state: string) => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetCity = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCountryAPiEndPoint}/city_list?state_code=${state}&alpha2=${country}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  editProfile = (data: any) => {
    let formData = new FormData();

    const genderres = data?.gender !== null ? data?.gender : "";
    const stateres = data?.state !== null ? data?.state : "";
    const cityres = data?.city !== null ? data?.city : "";
    const professionres = data?.profession !== null ? data?.profession : "";
    const partnership_practitionerres = data?.partnership_practitioner !== null ? data?.partnership_practitioner : "";
    const organisationres =data?.organizational_affilation !== null ? data?.organizational_affilation : "";


    formData.append('data[attributes][full_name]', data?.full_name);
    formData.append('data[attributes][email]', data?.email);
    formData.append('data[attributes][full_phone_number]', data?.full_phone_number);
    formData.append('data[attributes][gender]',  genderres);
    formData.append('data[attributes][country]', data?.country);
    formData.append('data[attributes][state]', stateres);
    formData.append('data[attributes][city]', cityres);
    formData.append('data[attributes][profession]', professionres);
    formData.append('data[attributes][preferred_language]', data?.preferred_language);
    formData.append('data[attributes][partnership_practitioner]', partnership_practitionerres);
    formData.append('data[attributes][accredited_partnership_broker]', data?.accredited_partnership_broker);
    formData.append('data[attributes][organizational_affilation]', organisationres);
    data.user_interests_attributes.forEach((i: any, index: number) => {
      if(Object.keys(i)[0] === "interest_id"){
        formData.append(`data[attributes][user_interests_attributes][${index}][interest_id]`, i.interest_id);
      } else{
        formData.append(`data[attributes][user_interests_attributes][${index}][interest_attributes][name]`,
        i.interest_attributes && i.interest_attributes.name)
        formData.append(`data[attributes][user_interests_attributes][${index}][interest_attributes][created_by_admin]'`,
        i.interest_attributes && i.interest_attributes.created_by_admin)
        formData.append(`data[attributes][user_interests_attributes][${index}][interest_attributes][interest_category]`,
        i.interest_attributes && i.interest_attributes.interest_category)
      }
    })
    data?.removed_interest.forEach((i: any, index: number) => {
      formData.append(`data[attributes][remove_interests][]`, i.interest_id);
    })
    const header = {
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiEditProfile = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editProfileApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiPutMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  editPasswrod = (data: any) => {
    let formData = new FormData();
    formData.append('data[attributes][current_password]', data?.current_password);
    formData.append('data[attributes][new_password]', data?.new_password);
    formData.append('data[attributes][confirm_password]', data?.confirm_password);
    const header = {
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiEditPassword = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editPasswordApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiPutMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleNavigationBack=()=>{
    this.props.navigation.goBack()
  }
  // Customizable Area End
}
